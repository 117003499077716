@import "./../required";

$list-badges-margin-x:      map-get($spacers, 1);
$list-badges-margin-y:      map-get($spacers, 1);
$list-badges-padding-x:     map-get($spacers, 2);
$list-badges-padding-y:     map-get($spacers, 1);
$list-badges-font-size:     $font-size-xs;

.list-badges {
    @include list-unstyled();

    display: flex;
    align-items: flex-start;
    font-size: $list-badges-font-size;
}

.list-badges.flex-column {
    .list-badges-item {
        margin-left: 0;
    }
}

.list-badges-item {
    padding: $list-badges-padding-y $list-badges-padding-x;
    margin-bottom: $list-badges-margin-y;
    font-weight: $font-weight-bold;
    color: $white;
    background-color: $secondary;
    border-radius: $border-radius;

    + .list-badges-item {
        margin-left: $list-badges-margin-x;
    }
}

.list-badges-item-discount {
    padding-top: $list-badges-padding-y * 2;
    padding-bottom: $list-badges-padding-y * 2;
    font-size: $font-size-sm;
    background-color: $red;
    border-radius: 0;
}
